import Logo from "./assets/logo.png"
import Sample from "./assets/management_schedule.png"

export function MainPage() {
  return (
    <>
      <div>

        <p className="centered">
          <img width="900" height="300" src={Logo} /></p>

        <p className="centered">
          <b><span style={{ "fontSize": "28px", "lineHeight": "125%" }} >
            Creative Visualizations, Data Analysis, and Application Development</span></b></p>

        <p className="centered">
          <span style={{ "fontSize": "24px", "lineHeight": "125%", "color": "#733635" }} >
            making a world of difference in how we understand life and business</span></p>

        <p ><span style={{ "fontSize": "16px", "lineHeight": "125%" }} >&nbsp;</span></p>

        <p className="centered">
          <b><span style={{ "fontSize": "20px", "lineHeight": "100%", "color": "#005F00" }} >
            Interactive Path Chart view for monday.com dependency-based projects</span></b></p>

        <p className="centered">
          <img width="90%" src={Sample} /></p>

        <p ><span style={{ "fontSize": "16px", "lineHeight": "125%" }} >&nbsp;</span></p>

        <p className="centered">
          <span style={{ "fontSize": "24px", "lineHeight": "125%" }} >
            contact Muhre Software Solutions at <a href="mailto:inquiries@muhre.com">inquiries@muhre.com</a></span></p>

      </div>
    </>
  );
}
