import Image001 from "../assets/csp_image001.png"
import Image002 from "../assets/csp_image002.png"
import Image003 from "../assets/csp_image003.png"
import Image004 from "../assets/csp_image004.png"
import Image005 from "../assets/csp_image005.png"

export function CSP_Monday() {
	return (
		<>
			<div className="normal-text">

				<h1>
					<img width="62" height="62" src={Image001} />
					<span style={{ "color": "#005F00", "paddingLeft": "20px" }} >CRM &amp; Sales Perspectives</span>
				</h1>

				<h2>
					<span style={{ "color": "#733635" }} >
						multi-faceted visualizations for monday.com CRM deals boards
					</span>
				</h2>

				<h3>
					<span>
						by Muhre Software Solutions
					</span>
				</h3>

				<p>&nbsp;</p>

				<h2>Introduction</h2>

				<p style={{ "display": "block", "margin-left": "3%", "margin-right": "3%" }} >
					Muhre&apos;s <b>CRM & Sales Perspectives</b> provides a suite of visualization and analytics tools
					that frame salesforce and sales pipeline data in a thought-inspiring manner.
					These multi-faceted and intuitive representations allow insights to rise clearly to the surface,
					enabling informed decision-making and timely responses from your team.
				</p>

				<p>&nbsp;</p>

				<h2>Installation</h2>

				<p style={{ "display": "block", "margin-left": "3%", "margin-right": "3%" }} >
					Add the feature you want as a view (from <img width="21" height="15" src={Image004} />Apps) to your monday.com CRM deals board.
					Map the columns on your board to the required columns (Assignment, Stage, Data Value, etc.) for the chosen feature.
					Then wait a moment for your board data to load and be displayed in the view.
				</p>

				<p>&nbsp;</p>

				<h2>Prerequisites</h2>

				<p style={{ "display": "block", "margin-left": "3%", "margin-right": "3%" }} >
					Each feature will have its own set of required columns to be mapped between your board and the app:
					Assignment, Stage, Data Value, Creation Date, Close Date, etc. <b>CRM & Sales Perspectives</b>
					will search your board&apos;s columns and attempt to establish default mappings based on column type.
					You can modify the column mapping at any time (e.g., choose a different assignment entity or set of data values).</p>

				<p>&nbsp;</p>

				<h2>Usage and Instructions</h2>

				<h3>
					<span style={{ "color": "#733635" }} >
						Deals Progress Timeline Bars
					</span>
				</h3>

				<span style={{ "display": "block", "margin-left": "10%", "margin-right": "10%" }} >

					<img width="900" height="300" src={Image002} style={{ "display": "block", "margin-left": "auto", "margin-right": "auto" }} />

					<p>&nbsp;</p>

					<p>
						Each individual deal is represented as a color-coded progress bar showing the timeline and duration spent in each stage of the sales pipeline.
						The thickness of the bar is proportional to the data value associated with the deal.
						Deals are ordered from top to bottom by creation date.
						Closed deals (won or lost) are identified by a symbol (a circle) following the termination of the bar.
						A color-coded patch appears before the start of each bar, representing the deal assignment.
					</p>

					<p style={{ "background": "silver", "padding": "20px" }}>
						<b>NOTE:</b> The effectiveness of this visualization is highly dependent on accurate record keeping within the deals board,
						as the color-coded stages for each item are drawn from the change history of the Stage Column values.
					</p>

					<p><b>Required Columns</b>: Assignment, Stage, Data Value, Creation Date, Close Date</p>

					<p style={{ "display": "block", "float": "left", "margin-left": "5%", "margin-right": "5%" }} >
						<img width="255" height="486" src={Image003} style={{ "display": "block", "margin-left": "auto", "margin-right": "auto" }} />
					</p>

					<p style={{ "margin-left": "3%", "paddingTop": "20px" }} >
						<b>Assignment Column</b> contains the ownership entity or assignment for each board item.
						The value for this column is represented by a color-coded patch placed before each timeline bar.
						Country, People, and Text column types are allowed.
					</p>

					<p style={{ "margin-left": "3%", "paddingTop": "20px" }} >
						<b>Stage Column</b> contains the status or pipeline stage for each board item.
						Changes to values in this column are monitored and represented by color-coded lengths within each timeline bar.
						Only Status column type is allowed.
					</p>

					<p style={{ "margin-left": "3%", "paddingTop": "20px" }} >
						<b>Data Value Column</b> contains the data value to be associated with each board item.
						The value for this column is represented by the relative thickness of each timeline bar.
						Only Numbers column type is allowed.
					</p>

					<p style={{ "margin-left": "3%", "paddingTop": "20px" }} >
						<b>Creation Date Column</b> contains the creation date for each board item.
						The value for this column is represented as the starting point for each timeline bar.
						If no creation date is specified, the current date (today) is applied.
						Only Date column type is allowed.
					</p>

					<p style={{ "margin-left": "3%", "paddingTop": "20px" }} >
						<b>Close Date Column</b> contains the close date for each board item.
						The value for this column is represented as the ending point for each timeline bar.
						If no close date is specified, the current date (today) is applied.
						Only Date column type is allowed.
					</p>

					<div style={{ "clear": "both" }} ></div >

					<h4>Deal Information Box</h4>
					<p style={{ "margin-left": "3%" }} >
						When the screen cursor hovers over a bar, an information box for that deal will appear in the lower left corner of the screen.
						The box contains the deal name, data value, assignment, and the dates that the deal entered each stage of the sales pipeline.
					</p>

					<h4>Deal Editing</h4>
					<p style={{ "margin-left": "3%" }} >
						Click on any location within a deal&apos;s progress bar to launch the monday.com item card for that deal.
						Changes made to the deal in the item card are saved to your board and will immediately be reflected in the view.
					</p>

					<h4>Filtering Items</h4>
					<p style={{ "margin-left": "3%" }} >
						<b>CRM & Sales Perspectives</b> will respond to monday.com filters applied to your deals board.
						Use the monday.com filter feature to limit the items (deals) promoted to the app views.
					</p>

				</span>

				<p>&nbsp;</p>

				<h3>
					<span style={{ "color": "#733635" }} >
						Deals Assignment & Stage Grid
					</span>
				</h3>

				<span style={{ "display": "block", "margin-left": "10%", "margin-right": "10%" }} >

					<img width="900" height="400" src={Image004} style={{ "display": "block", "margin-left": "auto", "margin-right": "auto" }} />

					<p>
						Each row in the grid shows the deals for a given assignment entity (sales rep, region, etc.) distributed by their current stage (columns) in the sales pipeline.
						Individual deals are represented as symbols (circles) color-coded by stage and sized proportional to the data value associated with each deal.
						Optionally, a secondary status such as priority, class, or segment can be displayed as a color-coded outer ring for each symbol.
						An assignment/stage subtotal is displayed within each cell of the grid along with a numeric percentage of the entire grid
						and a histogram bar of the percentage for the row.
						Below the last row of the grid, a cumulative stage subtotal is displayed along with a numeric percentage and histogram bar of the percentage.
					</p>

					<p><b>Required Columns</b>: Assignment, Stage, Data Value</p>

					<p><b>Optional Column</b>: Secondary Status</p>

					<p style={{ "display": "block", "float": "left", "margin-left": "5%", "margin-right": "5%" }} >
						<img width="255" height="410" src={Image005} style={{ "display": "block", "margin-left": "auto", "margin-right": "auto" }} />
					</p>

					<p style={{ "margin-left": "3%", "paddingTop": "20px" }} >
						<b>Assignment Column</b> contains the ownership entity or assignment for each board item.
						The value for this column is represented by the rows within the grid.
						Country, People, and Text column types are allowed.
					</p>

					<p style={{ "margin-left": "3%", "paddingTop": "20px" }} >
						<b>Stage Column</b> contains the status or pipeline stage for each board item.
						The values for this column are represented by the columns within the grid
						Only Status column type is allowed.
					</p>

					<p style={{ "margin-left": "3%", "paddingTop": "20px" }} >
						<b>Data Value Column</b> contains the data value to be associated with each board item.
						The value for this column is represented by the relative size (area) of each board item symbol (circle) within the grid.
						Only Numbers column type is allowed.
					</p>

					<p style={{ "margin-left": "3%", "paddingTop": "20px" }} >
						<b>Secondary Status Column</b> contains the optional secondary status for each board item.
						The value for this column is represented by a color-coded halo (ring) around the symbol (circle) for each board item.
						Only Status column type is allowed.
					</p>

					<div style={{ "clear": "both" }} ></div >

					<h4>Deal Information Box</h4>
					<p style={{ "margin-left": "3%" }} >
						When the screen cursor hovers over a deal symbol, an information box for that deal will appear within the grid cell.
						The box contains the deal name, data value, and (optionally) the secondary status designation.
					</p>

					<h4>Deal Editing</h4>
					<p style={{ "margin-left": "3%" }} >
						Click on any deal symbol to launch the monday.com item card for that deal.
						Changes made to the deal in the item card are saved to your board and will immediately be reflected in the view.
					</p>

					<h4>Filtering Items</h4>
					<p style={{ "margin-left": "3%" }} >
						<b>CRM & Sales Perspectives</b> will respond to monday.com filters applied to your deals board.
						Use the monday.com filter feature to limit the items (deals) promoted to the app views.
					</p>

				</span>

				<p>&nbsp;</p>

			</div>
		</>
	);
}
